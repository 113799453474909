var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var AY,ema,CY,fma,gma,hma,EY,jma,kma,lma,mma,HY,IY,JY,KY,MY,oma,NY,OY,pma,qma,rma,GY,ima,PY,QY,nma,SY;AY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
ema=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.j?$CLJS.lj.j(k,l,m):$CLJS.lj.call(null,k,l,m),$CLJS.kl.j?$CLJS.kl.j(k,l,m):$CLJS.kl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.g?$CLJS.lj.g(k,l):$CLJS.lj.call(null,k,l),$CLJS.kl.g?$CLJS.kl.g(k,l):$CLJS.kl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.h?$CLJS.lj.h(k):$CLJS.lj.call(null,k),$CLJS.kl.h?$CLJS.kl.h(k):$CLJS.kl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj.o?$CLJS.lj.o():$CLJS.lj.call(null),$CLJS.kl.o?$CLJS.kl.o():$CLJS.kl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.lj,m,t,u,v),$CLJS.Se.N($CLJS.kl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.BY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
CY=function(a){return $CLJS.OL($CLJS.iW)(a,$CLJS.Cf,$CLJS.Cf)};fma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.XF,b),$CLJS.ft(2,2,c))};gma=function(a,b){var c=$CLJS.dm(a,$CLJS.EQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.zt.g?$CLJS.zt.g(c,b):$CLJS.zt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.EQ,b)}return a};hma=function(a){return $CLJS.gf.g($CLJS.JV(a),$CLJS.KV(a))};
$CLJS.DY=function(a,b){return $CLJS.uW(a)&&$CLJS.E.g($CLJS.A(a),b)};EY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ns,null,$CLJS.rl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.uu(2,c):$CLJS.uu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?AY(2,c):AY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.sX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Ok.h($CLJS.yl),l):$CLJS.xd(k)?$CLJS.FA($CLJS.Wa,l):l}):$CLJS.Fk.g(a,f)};
jma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return EY(b,ima,d)},a,hma(a))};
kma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.nL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(ema(),$CLJS.lf($CLJS.Nk.j(FY,$CLJS.A,$CLJS.kl),$CLJS.Rs.h(CY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=EY(d,f,k),m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.convert",m))){var t=$CLJS.yB.l($CLJS.H([$CLJS.yz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.yB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.TL(CY(d));$CLJS.GA("metabase.lib.convert",
m,$CLJS.rW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yz,z.call(x,$CLJS.H([C]))])),$CLJS.yB.l($CLJS.H([$CLJS.nz,$CLJS.sW($CLJS.A($CLJS.bu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=jma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.nL.h(a))-1))return a;b+=1}else a=$CLJS.Qk.N(a,$CLJS.nL,$CLJS.R,b,d)}};
lma=function(a){var b=$CLJS.FW();return $CLJS.Pk.g(function(c){return $CLJS.E.g($CLJS.VE.h(c),"__join")?$CLJS.Qk.j(c,$CLJS.VE,b):c},a)};mma=function(a){return"string"===typeof $CLJS.cP.h(a)?$CLJS.Fk.g($CLJS.R.j(a,$CLJS.LV,$CLJS.DW($CLJS.cP.h(a))),$CLJS.cP):a};HY=function(a,b){var c=$CLJS.J.j(a,b,GY);if($CLJS.E.g(c,GY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[nma,a,$CLJS.Uw,b],null));return c};
IY=function(){return $CLJS.Ok.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};JY=function(a){return $CLJS.eg.j($CLJS.N,IY(),a)};KY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Nk.g(IY(),$CLJS.Ok.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
MY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.LY),c);a=$CLJS.E.g(a,$CLJS.RF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(KY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sM,a,b],null):a};oma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Nk.g($CLJS.hf.h(function(b){return $CLJS.AA(b,$CLJS.DB)}),$CLJS.hf.h($CLJS.LY)),$CLJS.zW.h(a))};
NY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.nL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.LY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.GQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.PO,oma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.lV.h(c)],null)},null,a));return $CLJS.n($CLJS.gQ.h(a))?$CLJS.wV(a,new $CLJS.h(null,1,[$CLJS.gQ,$CLJS.EF],null)):a};
OY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.RW(a,b,$CLJS.Nk.g($CLJS.LY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.RW(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),$CLJS.hf.h($CLJS.LY),e)}):d;return $CLJS.wV(a,$CLJS.Ee([b,c]))};pma=function(a){var b=$CLJS.LV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Fk.g(a,$CLJS.LV),$CLJS.cP,["card__",$CLJS.p.h(b)].join("")):a};
qma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);rma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);GY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ima=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);PY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
QY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.RY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);nma=new $CLJS.M(null,"m","m",1632677161);SY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var FY,sma,tma,uma,vma,wma,xma,yma,zma,Ama,Bma;$CLJS.TY=$CLJS.N;$CLJS.UY=$CLJS.N;FY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.fW,null,$CLJS.RE,null,$CLJS.BN,null,$CLJS.XP,null,$CLJS.WF,null,$CLJS.MR,null,$CLJS.XF,null],null),null);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.Xe($CLJS.N);wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.eC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.VY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.cC,wma,sma,tma,uma,vma);$CLJS.VY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.mW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.VY),d))}else b=a;return b});$CLJS.VY.m(null,$CLJS.eW,function(a){return a});
$CLJS.VY.m(null,$CLJS.SV,function(a){var b=$CLJS.VY.h($CLJS.XF.h(a)),c=$CLJS.Fe($CLJS.Pk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.wW($CLJS.VY.h(t),u)},$CLJS.XP.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.em(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.em(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.yE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.UY,k=$CLJS.TY;$CLJS.UY=d;$CLJS.TY=e;try{var l=fma(mma(a),b,$CLJS.H([$CLJS.XP,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Qk.j(t,u,$CLJS.VY)},l,$CLJS.Gk.l(FY,$CLJS.XF,$CLJS.H([$CLJS.XP])));return $CLJS.n($CLJS.BN.h(m))?$CLJS.Qk.j(m,$CLJS.BN,lma):m}finally{$CLJS.TY=k,$CLJS.UY=f}});$CLJS.VY.m(null,$CLJS.ZV,function(a){return gma(a,function(b){return $CLJS.RW(b,$CLJS.BF,$CLJS.VY)})});
$CLJS.VY.m(null,$CLJS.iL,function(a){a=$CLJS.Qk.j($CLJS.Qk.j(a,$CLJS.qL,$CLJS.VY),$CLJS.nL,$CLJS.VY);var b=$CLJS.n($CLJS.RE.h(a))?$CLJS.Qk.j(a,$CLJS.RE,function(c){return $CLJS.Kl(c)?$CLJS.Pk.g($CLJS.VY,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.VE.h(a))?$CLJS.R.j(b,$CLJS.VE,"__join"):b});$CLJS.VY.m(null,$CLJS.jB,function(a){return $CLJS.Pk.g($CLJS.VY,a)});
$CLJS.VY.m(null,$CLJS.lB,function(a){return $CLJS.n($CLJS.lj.h(a))?kma($CLJS.R.j($CLJS.Qk.j($CLJS.AW(a),$CLJS.nL,function(b){return $CLJS.Pk.g($CLJS.VY,b)}),$CLJS.RY,!0)):$CLJS.zt(a,$CLJS.VY)});$CLJS.VY.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.mW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,b],null))});
$CLJS.VY.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.wV(a,new $CLJS.h(null,3,[$CLJS.dD,$CLJS.pB,$CLJS.BO,$CLJS.Tj,$CLJS.UR,$CLJS.tE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.pB.h(a),d=$CLJS.n(d)?d:$CLJS.ME(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.mW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null))});
$CLJS.VY.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.Fk.g(c,$CLJS.ci),$CLJS.Pk.g($CLJS.VY,b)],null);b=$CLJS.mW(b);return null!=a?$CLJS.be.g(b,$CLJS.VY.h(a)):b});$CLJS.VY.m(null,$CLJS.mB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.mW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.VY.m(null,$CLJS.XF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.mW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,HY($CLJS.UY,c)],null))});$CLJS.VY.m(null,$CLJS.sM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.VY.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.el.l($CLJS.H([c,a]))],null),d)});xma=$CLJS.Xe($CLJS.N);yma=$CLJS.Xe($CLJS.N);
zma=$CLJS.Xe($CLJS.N);Ama=$CLJS.Xe($CLJS.N);Bma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.eC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.LY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.cC,Bma,xma,yma,zma,Ama);
$CLJS.LY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.LY,d);a=KY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.LY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.zt(JY(a),$CLJS.LY):
a;return a});for(var WY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[SY,QY],null)),XY=null,YY=0,ZY=0;;)if(ZY<YY){var Cma=XY.X(null,ZY);$CLJS.pF(Cma,PY);ZY+=1}else{var $Y=$CLJS.y(WY);if($Y){var aZ=$Y;if($CLJS.Ad(aZ)){var bZ=$CLJS.lc(aZ),Dma=$CLJS.mc(aZ),Ema=bZ,Fma=$CLJS.D(bZ);WY=Dma;XY=Ema;YY=Fma}else{var Gma=$CLJS.A(aZ);$CLJS.pF(Gma,PY);WY=$CLJS.B(aZ);XY=null;YY=0}ZY=0}else break}
for(var cZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Qx,$CLJS.bG,$CLJS.qG,$CLJS.NF,$CLJS.hk,$CLJS.vG,$CLJS.Xn,$CLJS.TF,$CLJS.oG,$CLJS.wG,$CLJS.uG,$CLJS.BG],null)),dZ=null,eZ=0,fZ=0;;)if(fZ<eZ){var Hma=dZ.X(null,fZ);$CLJS.pF(Hma,SY);fZ+=1}else{var gZ=$CLJS.y(cZ);if(gZ){var hZ=gZ;if($CLJS.Ad(hZ)){var iZ=$CLJS.lc(hZ),Ima=$CLJS.mc(hZ),Jma=iZ,Kma=$CLJS.D(iZ);cZ=Ima;dZ=Jma;eZ=Kma}else{var Lma=$CLJS.A(hZ);$CLJS.pF(Lma,SY);cZ=$CLJS.B(hZ);dZ=null;eZ=0}fZ=0}else break}
for(var jZ=$CLJS.y($CLJS.yf([$CLJS.Ur,$CLJS.it,$CLJS.Vr,$CLJS.CG,$CLJS.RF,$CLJS.kG,$CLJS.IF,$CLJS.EG,$CLJS.zF,$CLJS.VF,$CLJS.DF,$CLJS.fG,$CLJS.mG,$CLJS.FF,$CLJS.YI,$CLJS.$I,$CLJS.Ey,$CLJS.UI,$CLJS.bJ,$CLJS.OI,$CLJS.fJ,$CLJS.TI,$CLJS.MI,$CLJS.eJ,$CLJS.mJ,$CLJS.iJ,$CLJS.SI,$CLJS.pJ,$CLJS.hJ,$CLJS.nJ,$CLJS.HF,$CLJS.AF,$CLJS.FG,$CLJS.YH,$CLJS.jG,$CLJS.Ox,$CLJS.xG,$CLJS.zG,$CLJS.GG,$CLJS.Ct,$CLJS.It],!0)),kZ=null,lZ=0,mZ=0;;)if(mZ<lZ){var Mma=kZ.X(null,mZ);$CLJS.pF(Mma,QY);mZ+=1}else{var nZ=$CLJS.y(jZ);
if(nZ){var oZ=nZ;if($CLJS.Ad(oZ)){var pZ=$CLJS.lc(oZ),Nma=$CLJS.mc(oZ),Oma=pZ,Pma=$CLJS.D(pZ);jZ=Nma;kZ=Oma;lZ=Pma}else{var Qma=$CLJS.A(oZ);$CLJS.pF(Qma,QY);jZ=$CLJS.B(oZ);kZ=null;lZ=0}mZ=0}else break}$CLJS.LY.m(null,PY,function(a){return MY(a)});$CLJS.LY.m(null,$CLJS.lB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Nk.g(IY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.LY.h(b)],null)})),a)});
$CLJS.LY.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=KY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,HY($CLJS.TY,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.qV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.LY.m(null,$CLJS.jB,function(a){return $CLJS.Pk.g($CLJS.LY,a)});$CLJS.LY.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.LY.h(a),KY(b)],null)});
$CLJS.LY.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=KY($CLJS.wV(b,new $CLJS.h(null,3,[$CLJS.pB,$CLJS.dD,$CLJS.Tj,$CLJS.BO,$CLJS.tE,$CLJS.UR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,a,b],null)});
$CLJS.LY.m(null,$CLJS.iL,function(a){var b=JY(a);a=0==$CLJS.VE.h(a).lastIndexOf("__join",0)?$CLJS.Fk.g(b,$CLJS.VE):b;return $CLJS.el.l($CLJS.H([$CLJS.zt($CLJS.Fk.l(a,$CLJS.nL,$CLJS.H([$CLJS.qL])),$CLJS.LY),OY($CLJS.cm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qL],null)),$CLJS.qL,$CLJS.OR),NY(a)]))});
$CLJS.LY.m(null,$CLJS.SV,function(a){var b=$CLJS.XF.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.em(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.em(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.UY,e=$CLJS.TY;$CLJS.UY=c;$CLJS.TY=b;try{return $CLJS.eb(function(f,k){return $CLJS.RW(f,
k,$CLJS.LY)},OY($CLJS.RW($CLJS.RW(pma(JY(a)),$CLJS.XF,function(f){return $CLJS.Pk.g(MY,f)}),$CLJS.XP,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.LY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vW(C),$CLJS.E.g($CLJS.Cj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.LY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vW(x),$CLJS.E.g($CLJS.Cj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.fW,$CLJS.MF),$CLJS.Gk.l(FY,$CLJS.XF,$CLJS.H([$CLJS.fW,$CLJS.XP])))}finally{$CLJS.TY=e,$CLJS.UY=d}});$CLJS.LY.m(null,$CLJS.ZV,function(a){return $CLJS.zt(JY(a),$CLJS.LY)});
$CLJS.LY.m(null,$CLJS.eW,function(a){try{var b=JY(a),c=$CLJS.sN.h(b),d=NY(b),e=$CLJS.E.g($CLJS.Xt.h($CLJS.id($CLJS.nL.h(a))),$CLJS.ZV)?$CLJS.gQ:$CLJS.EF;return $CLJS.el.l($CLJS.H([$CLJS.Fk.l(b,$CLJS.nL,$CLJS.H([$CLJS.sN,$CLJS.RY])),function(){var k=$CLJS.Ee([$CLJS.lj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.sN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.qV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.EF,a],null),f);}});
$CLJS.qZ=function(){function a(d,e,f){f=$CLJS.zY(null,$CLJS.Lz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.CW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.XF);k=$CLJS.eg.j($CLJS.N,$CLJS.em(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.em(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.yE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.UY,t=$CLJS.TY;$CLJS.UY=k;$CLJS.TY=l;try{try{return $CLJS.VY.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.qV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.EF,d,$CLJS.BL,e,rma,f,qma,$CLJS.UY],null),u);}}finally{$CLJS.TY=t,$CLJS.UY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();