var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var d8,f8,g8,h8,i8,k8,l8,Xra,m8,n8,Yra,o8,Zra,$ra,p8,q8,r8,s8,t8,asa,u8,bsa,csa,dsa,esa,v8,fsa,gsa,hsa,w8,isa,j8,jsa,ksa,lsa,msa,nsa,osa,x8,psa,qsa,rsa,ssa,tsa,usa,vsa,y8,wsa,xsa,ysa,zsa,Asa,Bsa,Csa,Dsa,Esa,Fsa,Gsa,Hsa,Isa,Jsa,Ksa,Lsa,z8,B8,Msa,Nsa,Osa,C8,Psa,Qsa,Rsa,Ssa,Tsa,Usa,Vsa,D8,Wsa,Xsa,Ysa,Zsa;$CLJS.c8=function(a){return $CLJS.gc($CLJS.eb(function(b,c){return $CLJS.hg.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))};
d8=function(a){var b=$CLJS.M_(a);return $CLJS.n(b)?$CLJS.lf($CLJS.A_,$CLJS.T_(a,b)):$CLJS.Cf};$CLJS.e8=function(a){return $CLJS.Qk.v(a,$CLJS.nL,$CLJS.be,new $CLJS.h(null,1,[$CLJS.Xt,$CLJS.SV],null))};
f8=function(a,b,c){var d=$CLJS.w2(a,$CLJS.BW(a,b)),e=$CLJS.Lk.g($CLJS.OK.h(c),$CLJS.gL)?new $CLJS.h(null,2,[$CLJS.EF,a,$CLJS.BL,b],null):$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.EF,a,$CLJS.BL,d],null):new $CLJS.h(null,2,[$CLJS.EF,$CLJS.e8(a),$CLJS.BL,-1],null),f=$CLJS.t3.g($CLJS.EF.h(e),$CLJS.BL.h(e));a=function(){var k=$CLJS.j2.v($CLJS.EF.h(e),$CLJS.BL.h(e),$CLJS.nW(c),f);if($CLJS.n(k))return k;k=$CLJS.a1.h(c);return $CLJS.n(k)?$CLJS.tH(function(l){return $CLJS.E.g($CLJS.a1.h(l),$CLJS.a1.h(c))},f):k}();
return $CLJS.R.j(e,$CLJS.Si,a)};g8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.Z5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.B_(c):c:e;return $CLJS.n(d)?(d=$CLJS.u_(c)?null:$CLJS.R.j($CLJS.A($CLJS.I2(c)),$CLJS.Xt,$CLJS.RJ),$CLJS.el.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.w6,$CLJS.u6,d],null),f8(a,b,c)]))):null};
h8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.Z5(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Lk.g($CLJS.OK.h(c),$CLJS.gL)&&!$CLJS.A_(c)&&!$CLJS.B_(c)&&!$CLJS.J_(c)&&!$CLJS.y_(c)&&$CLJS.Va($CLJS.y($CLJS.X5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.T6,$CLJS.Si,c],null):null};
i8=function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.Si);b=$CLJS.J.g(b,$CLJS.Cj);return $CLJS.n($CLJS.n(a)?null!=b&&$CLJS.Lk.g(b,$CLJS.N2)&&!$CLJS.A_(a)&&$CLJS.z_(a):a)?new $CLJS.h(null,3,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.A6,$CLJS.MF,$CLJS.mW(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,$CLJS.N,$CLJS.nW(a),b],null))],null):null};k8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.Cj);return $CLJS.n($CLJS.n(d)?null!=e:d)?j8(a,b,c,1<$CLJS.D(d8(a))):null};
l8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.Si);e=$CLJS.J.g(e,$CLJS.Cj);var f=$CLJS.Z5(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.OK.h(c),$CLJS.gL):c:f;return $CLJS.n(c)?$CLJS.lf(d,$CLJS.W5.g(a,b)):null};
Xra=function(a,b){var c=$CLJS.c8($CLJS.hf.g(function(d){d=$CLJS.X_.j(a,b,d);return $CLJS.u_(d)?$CLJS.zP:$CLJS.D_(d)?$CLJS.G7:$CLJS.x_(d)?$CLJS.AG:null},$CLJS.U5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.zP,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.AG,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.zP,1,$CLJS.AG,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.AG,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.G7,1],null),c)?new $CLJS.ah(null,
new $CLJS.h(null,2,[$CLJS.AG,null,$CLJS.Ey,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.AG,null,$CLJS.Ey,null,$CLJS.q6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.AG,1],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.AG,null,$CLJS.Ey,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.AG,2],null),c)?new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.AG,null,$CLJS.Ey,null],null),null):$CLJS.bh};
m8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.Si);c=$CLJS.J.g(d,$CLJS.EL);var f=$CLJS.J.g(d,$CLJS.Cj);if($CLJS.n(function(){var m=$CLJS.Z5(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.OK.h(e),$CLJS.gL)&&0<$CLJS.D($CLJS.G4.g(a,b)):e:m}())){var k=Xra(a,b),l=$CLJS.eg.g($CLJS.N,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.Ad(v)){var x=$CLJS.lc(v),z=$CLJS.D(x),C=$CLJS.qe(z);a:for(var G=0;;)if(G<z){var K=$CLJS.kd(x,G),S=$CLJS.J.g($CLJS.H7,
K);S=l8(a,b,d,S);$CLJS.n($CLJS.Fe(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}C=$CLJS.A(v);x=$CLJS.J.g($CLJS.H7,C);x=l8(a,b,d,x);if($CLJS.n($CLJS.Fe(x)))return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null},null,null)}(k)}());return $CLJS.td(l)?null:new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.b7,$CLJS.EL,c,$CLJS.m6,l],null)}return null};
n8=function(a,b){return $CLJS.mW($CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Yra=function(a,b){var c=$CLJS.nW(a);if($CLJS.B_(a))return $CLJS.Cf;if($CLJS.E.g(b,$CLJS.N2))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.MF,n8($CLJS.JF,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.MF,n8($CLJS.UF,$CLJS.H([c]))],null)],null);if($CLJS.v_(a)||$CLJS.u_(a))return a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,
u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.MF,n8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.MF,n8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pr,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.Lr,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yG,"≠"],null)],null));a=function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.MF,n8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),
f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ee(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.MF,n8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yG,"≠"],null)],null))};
o8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=(e=$CLJS.Z5(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.A_(c)&&!$CLJS.z_(c):c:e;return $CLJS.n(e)?(a=f8(a,b,c),$CLJS.el.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.f6,$CLJS.zL,Yra($CLJS.Si.h(a),d),$CLJS.Cj,d],null),a]))):null};Zra=function(a,b,c){return $CLJS.j2.v(a,b,$CLJS.nW(c),$CLJS.N7.g(a,b))};
$ra=function(a,b,c){a=$CLJS.P7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
p8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.Cj);if($CLJS.n(function(){var k=$CLJS.Z5(a,b);return k?$CLJS.n(d)?null==e&&!$CLJS.B_(d):d:k}())&&$CLJS.n(Zra(a,b,d))){var f=$ra(a,b,d);return new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.G6,$CLJS.Si,d,$CLJS.J6,function(){switch(f instanceof $CLJS.M?f.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.YF],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eG],null);default:return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.eG,$CLJS.YF],null)}}()],null)}return null};
q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.Z5(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.B_(c)&&$CLJS.Lk.g($CLJS.OK.h(c),$CLJS.gL)&&$CLJS.Va($CLJS.y($CLJS.X5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.gf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NF],null),$CLJS.w_(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uG,$CLJS.bG],null):null),new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.H6,$CLJS.Si,c,$CLJS.L6,a],null)):null};
r8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);var e=$CLJS.Z5(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.B_(c)&&$CLJS.w_(c)&&$CLJS.Lk.g($CLJS.OK.h(c),$CLJS.gL):c:e;if($CLJS.n(d)&&(d=$CLJS.tH($CLJS.u_,$CLJS.W5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.tH($CLJS.ci,$CLJS.V0.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.w3(d,e);return $CLJS.n($CLJS.GV($CLJS.hf.g($CLJS.nW,$CLJS.ee(f,$CLJS.U5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.Z6,$CLJS.Si,c,$CLJS.WF,d,$CLJS.ri,$CLJS.S0.h(e)],
null):null}return null};
s8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.h6),f=$CLJS.J.g(c,$CLJS.EL),k=$CLJS.J.g(c,$CLJS.Cj);return $CLJS.n(function(){var l=$CLJS.Z5(a,b);return l?$CLJS.n(d)?(l=null!=k)?(l=$CLJS.Fe(f),$CLJS.n(l)?!$CLJS.B_(d):l):l:d:l}())?new $CLJS.h(null,6,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.V6,$CLJS.Q6,"number"===typeof k?k:2,$CLJS.KZ,function(){var l=$CLJS.M_(a);l=null==l?null:$CLJS.S_(a,l);return null==l?null:$CLJS.Y_.j(a,b,l)}(),$CLJS.EL,f,$CLJS.h6,e],null):null};
t8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.Si);d=$CLJS.J.g(d,$CLJS.Cj);if($CLJS.n($CLJS.n(c)?d:c))if(a=$CLJS.A($CLJS.X5.j(a,b,c)),$CLJS.n(a))if(a=$CLJS.m7(a),$CLJS.n(a))if(b=$CLJS.S7(c,d),$CLJS.n(b)){var e=$CLJS.O(b);b=$CLJS.J.g(e,$CLJS.DL);var f=$CLJS.J.g(e,$CLJS.IL);e=$CLJS.J.g(e,$CLJS.BE);var k=$CLJS.CE.h(a);k=k instanceof $CLJS.M?k.T:null;switch(k){case "num-bins":return new $CLJS.h(null,6,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.K6,$CLJS.Si,c,$CLJS.DL,d,$CLJS.IL,d+e,$CLJS.D6,new $CLJS.h(null,
1,[$CLJS.CE,$CLJS.ci],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.K6,$CLJS.Si,c,$CLJS.DL,b,$CLJS.IL,f,$CLJS.D6,$CLJS.Qk.j(a,$CLJS.BE,function(l){return l/10})],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
asa=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.g6);b=$CLJS.I0.j(a,b,$CLJS.CW(a,b));a=$CLJS.tH($CLJS.H_,b);if($CLJS.n(a)&&(b=$CLJS.tH($CLJS.I_,b),$CLJS.n(b))){var e=function(f){return $CLJS.Re(function(k){var l=$CLJS.Si.h(k);l=$CLJS.n($CLJS.Yi.h(f))?$CLJS.E.g($CLJS.Yi.h(f),$CLJS.Yi.h(l)):$CLJS.E.g($CLJS.HZ.h(f),$CLJS.HZ.h(l));return $CLJS.n(l)?$CLJS.Cj.h(k):null},d)};return $CLJS.R.l(c,$CLJS.Y7,a,$CLJS.H([$CLJS.Z7,b,$CLJS.$7,e(a),$CLJS.X7,e(b)]))}return null};
u8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.Si);var d=$CLJS.J.g(c,$CLJS.Cj),e=$CLJS.J.g(c,$CLJS.Y7);c=$CLJS.J.g(c,$CLJS.Z7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.f7,$CLJS.l6,$CLJS.y6,$CLJS.Si,a,$CLJS.Cj,d,$CLJS.B6,new $CLJS.h(null,2,[$CLJS.Si,e,$CLJS.BE,b],null),$CLJS.z6,new $CLJS.h(null,2,[$CLJS.Si,c,$CLJS.BE,b],null)],null):null};bsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Si);return $CLJS.n(null==b?null:$CLJS.G_(b))?u8(a,10):null};
csa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Si);return $CLJS.n(null==b?null:$CLJS.F_(b))?u8(a,1):null};dsa=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.Si);return $CLJS.n(null==b?null:$CLJS.E_(b))?u8(a,.1):null};
esa=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.Y7);var c=$CLJS.J.g(b,$CLJS.Z7),d=$CLJS.J.g(b,$CLJS.$7);b=$CLJS.J.g(b,$CLJS.X7);if($CLJS.n($CLJS.n(d)?b:d)){var e=$CLJS.BE.h($CLJS.m7(a));if($CLJS.n(e)){var f=$CLJS.BE.h($CLJS.m7(c));if($CLJS.n(f)){var k=20<=e&&20<=f?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[e/10,f/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.f7,$CLJS.l6,$CLJS.d6,$CLJS.B6,new $CLJS.h(null,
4,[$CLJS.Si,a,$CLJS.BE,l,$CLJS.Xn,d,$CLJS.hk,d+e],null),$CLJS.z6,new $CLJS.h(null,4,[$CLJS.Si,c,$CLJS.BE,k,$CLJS.Xn,b,$CLJS.hk,b+f],null)],null)}}}return null};v8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Cj);if($CLJS.n(d)){var e=asa(a,b,c);return $CLJS.n(e)?$CLJS.Re(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[bsa,csa,dsa,esa],null)):null}return null};
fsa=function(a,b,c){return $CLJS.A(function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.A(m);if($CLJS.n(function(){var u=$CLJS.DY(t,$CLJS.hG);return u?$CLJS.E2(t):u}())&&(l=$CLJS.y(function(u,v,x,z){return function K(G){return new $CLJS.ne(null,function(S,V){return function(){for(var Z=G;;)if(Z=$CLJS.y(Z)){if($CLJS.Ad(Z)){var ha=$CLJS.lc(Z),ra=$CLJS.D(ha),Na=$CLJS.qe(ra);return function(){for(var fb=0;;)if(fb<ra){var Ua=$CLJS.kd(ha,
fb),mb=$CLJS.O(Ua);Ua=mb;mb=$CLJS.J.g(mb,$CLJS.Si);var Ja=$CLJS.j2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[mb],null));mb=$CLJS.n(Ja)?$CLJS.E.g($CLJS.E2(V),$CLJS.E2(mb)):Ja;$CLJS.n(mb)&&(Ua=$CLJS.R.j(Ua,$CLJS.h6,V),Na.add(Ua));fb+=1}else return!0}()?$CLJS.te($CLJS.ve(Na),K($CLJS.mc(Z))):$CLJS.te($CLJS.ve(Na),null)}var zb=$CLJS.A(Z),Pa=zb=$CLJS.O(zb),Za=$CLJS.J.g(zb,$CLJS.Si);if($CLJS.n(function(){var fb=$CLJS.j2.g(V,new $CLJS.P(null,1,5,$CLJS.Q,[Za],null));return $CLJS.n(fb)?$CLJS.E.g($CLJS.E2(V),$CLJS.E2(Za)):
fb}()))return $CLJS.ee($CLJS.R.j(Pa,$CLJS.h6,V),K($CLJS.Lc(Z)));Z=$CLJS.Lc(Z)}else return null}}(u,v,x,z),null,null)}}(k,t,m,l)(c))))return $CLJS.gf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.U5.g(a,b))}())};gsa=function(a){a=$CLJS.S0.h(a);return $CLJS.n(a)?$CLJS.Id($CLJS.fh($CLJS.a8),a)?$CLJS.b8.h?$CLJS.b8.h(a):$CLJS.b8.call(null,a):null:null};
hsa=function(a){a=a instanceof $CLJS.M?a.T:null;switch(a){case "quarter":return $CLJS.JE("See this year by quarter");case "month":return $CLJS.JE("See this quarter by month");case "week":return $CLJS.JE("See this month by week");case "day":return $CLJS.JE("See this week by day");case "hour":return $CLJS.JE("See this day by hour");case "minute":return $CLJS.JE("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
w8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Si),e=$CLJS.J.g(c,$CLJS.EL),f=$CLJS.J.g(c,$CLJS.Cj);if($CLJS.n(function(){var l=$CLJS.Z5(a,b);return l?$CLJS.n(d)?(l=$CLJS.Fe(e),$CLJS.n(l)?null!=f:l):d:l}())&&(c=fsa(a,b,e),$CLJS.n(c))){var k=gsa($CLJS.Si.h(c));return $CLJS.n(k)?new $CLJS.h(null,5,[$CLJS.Xt,$CLJS.LL,$CLJS.mE,hsa(k),$CLJS.lj,$CLJS.d7,$CLJS.BF,c,$CLJS.Y6,k],null):null}return null};
isa=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.EL);return $CLJS.Fe(function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.el.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.el.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};
j8=function j8(a,b,c,d){c=$CLJS.O(c);var f=$CLJS.J.g(c,$CLJS.Si),k=$CLJS.J.g(c,$CLJS.g6),l=$CLJS.J.g(c,$CLJS.Cj);l=new $CLJS.h(null,5,[$CLJS.Xt,$CLJS.LL,$CLJS.lj,$CLJS.GL,$CLJS.Si,f,$CLJS.xL,l,$CLJS.wL,d],null);var m=$CLJS.Z5(a,b);if($CLJS.n(function(){var u=$CLJS.A_(f);return u?$CLJS.n(d)?m:d:u}()))return $CLJS.R.j(l,$CLJS.lj,$CLJS.GL);if($CLJS.A_(f))return $CLJS.R.j(l,$CLJS.lj,$CLJS.j7);if($CLJS.z_(f))return $CLJS.R.j(l,$CLJS.lj,$CLJS.HL);if($CLJS.n(function(){var u=$CLJS.Va(d);return u?(u=$CLJS.Fe(k),
$CLJS.n(u)?$CLJS.td($CLJS.G4.g(a,b)):u):u}())){l=d8(a);var t=$CLJS.I(l,0,null);l=$CLJS.Cj.h($CLJS.tH(function(u){return $CLJS.E.g($CLJS.T.h($CLJS.Si.h(u)),$CLJS.T.h(t))},k));if($CLJS.n($CLJS.n(l)?$CLJS.Lk.g($CLJS.T.h(f),$CLJS.T.h(t)):l))return c=$CLJS.R.l(c,$CLJS.Si,t,$CLJS.H([$CLJS.Cj,l])),j8.v?j8.v(a,b,c,d):j8.call(null,a,b,c,d)}return null};jsa=new $CLJS.r(null,"_query","_query",1604043437,null);ksa=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);
lsa=new $CLJS.r(null,"_stage-number","_stage-number",1726062348,null);msa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);nsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);osa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);
x8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);psa=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);qsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);rsa=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);ssa=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);
tsa=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);usa=new $CLJS.M(null,"drill","drill",-1704624793);vsa=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);y8=new $CLJS.r(null,"_context","_context",-1238791322,null);wsa=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);
xsa=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);ysa=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);zsa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);
Asa=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);Bsa=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);Csa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);Dsa=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);
Esa=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Fsa=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);Gsa=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);
Hsa=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Isa=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);Jsa=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Ksa=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);
Lsa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);z8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);B8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Msa=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);
Nsa=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);Osa=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);C8=new $CLJS.r(null,"context","context",810340414,null);Psa=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);Qsa=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
Rsa=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);Ssa=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);Tsa=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);Usa=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
Vsa=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);D8=new $CLJS.r(null,"query","query",352022017,null);Wsa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);Xsa=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);
Ysa=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);Zsa=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var $sa;$CLJS.E0.m(null,$CLJS.LL,function(a,b,c){return $CLJS.b6.j(a,b,c)});
$sa=new $CLJS.P(null,13,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return h8},Asa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.k6],null)],null),wsa,ssa,"metabase/lib/drill_thru/distribution.cljc",28,1,16,16,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
1,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(h8)?h8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return g8},Msa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.s6],null)],null),Osa,Isa,"metabase/lib/drill_thru/column_filter.cljc",29,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
1,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(g8)?g8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return i8},Xsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.W6],null)],null),Usa,Bsa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,13,13,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[jsa,lsa,new $CLJS.h(null,
2,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null),$CLJS.gt,y8],null)],null)),"Inputs: [_query :- :metabase.lib.schema/query _stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(i8)?i8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return k8},Rsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ps,$CLJS.e6,$CLJS.X6,$CLJS.N6],null)],null)],null),ysa,Nsa,"metabase/lib/drill_thru/object_details.cljc",
29,1,47,47,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,2,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null),$CLJS.gt,C8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(k8)?k8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return m8},Ssa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.n6],null)],null),ksa,Dsa,"metabase/lib/drill_thru/pivot.cljc",21,1,76,76,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
2,[$CLJS.lp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yz,z8,$CLJS.Ui],null),$CLJS.gt,C8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(m8)?m8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return o8},vsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.O6],null)],null),Esa,Zsa,"metabase/lib/drill_thru/quick_filter.cljc",28,1,39,39,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
1,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(o8)?o8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return p8},Ysa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.g7],null)],null),Ksa,tsa,"metabase/lib/drill_thru/sort.cljc",20,1,34,34,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
2,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null),$CLJS.gt,y8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(p8)?p8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return q8},Vsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,
$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.v6],null)],null),Csa,Hsa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,11,11,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,1,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(q8)?q8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return r8},Gsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.t6],null)],null),osa,Qsa,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,15,15,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,
[D8,x8,new $CLJS.h(null,1,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(r8)?
r8.H:null])),B8,!0],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return s8},Jsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.e7],null)],null),Fsa,psa,"metabase/lib/drill_thru/underlying_records.cljc",34,1,19,19,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
1,[$CLJS.lp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Yz,Tsa,z8,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.",
$CLJS.n(s8)?s8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return w8},qsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.$6],null)],null),Wsa,rsa,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,56,56,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,
new $CLJS.h(null,1,[$CLJS.lp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yz,z8,$CLJS.Ui],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(w8)?w8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return v8},zsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.a7],null)],null),Lsa,Psa,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,135,135,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,
x8,new $CLJS.h(null,2,[$CLJS.lp,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ui],null),$CLJS.gt,C8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(v8)?v8.H:null])),B8,!1],null),new $CLJS.h(null,2,[$CLJS.at,new $CLJS.Gc(function(){return t8},nsa,$CLJS.Ig([$CLJS.nl,$CLJS.Vi,$CLJS.T,$CLJS.nk,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.wk,$CLJS.ti,$CLJS.ik,$CLJS.mk],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eo,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,$CLJS.JL,$CLJS.Bs,$CLJS.p6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.c7],null)],null),msa,xsa,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,54,54,$CLJS.U(new $CLJS.P(null,3,5,$CLJS.Q,[D8,x8,new $CLJS.h(null,
2,[$CLJS.lp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,$CLJS.Ui],null),$CLJS.gt,y8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(t8)?t8.H:null])),B8,!1],null)],null);
$CLJS.ata=function(){function a(d,e,f){try{var k=isa(f);return $CLJS.eg.g($CLJS.Cf,function(){return function u(t){return new $CLJS.ne(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var z=x,C=$CLJS.A(z),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.at),S=$CLJS.J.g(G,B8);if(x=$CLJS.y(function(V,Z,ha,ra,Na,zb,Pa,Za){return function mb(Ua){return new $CLJS.ne(null,function(Ja,bc,$d,Hd){return function(){for(var qd=Ua;;)if(qd=$CLJS.y(qd)){if($CLJS.Ad(qd)){var ql=$CLJS.lc(qd),A8=$CLJS.D(ql),pp=$CLJS.qe(A8);
a:for(var qQ=0;;)if(qQ<A8){var sB=$CLJS.kd(ql,qQ);sB=Hd.j?Hd.j(d,e,sB):Hd.call(null,d,e,sB);$CLJS.n(sB)&&pp.add(sB);qQ+=1}else{ql=!0;break a}return ql?$CLJS.te($CLJS.ve(pp),mb($CLJS.mc(qd))):$CLJS.te($CLJS.ve(pp),null)}pp=$CLJS.A(qd);pp=Hd.j?Hd.j(d,e,pp):Hd.call(null,d,e,pp);if($CLJS.n(pp))return $CLJS.ee(pp,mb($CLJS.Lc(qd)));qd=$CLJS.Lc(qd)}else return null}}(V,Z,ha,ra,Na,zb,Pa,Za),null,null)}}(v,C,G,K,S,z,x,k)($CLJS.n(function(){var V=S;return $CLJS.n(V)?k:V}())?k:new $CLJS.P(null,1,5,$CLJS.Q,[f],
null))))return $CLJS.gf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}($sa)}())}catch(m){var l=m;throw $CLJS.gi(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.qV(l))].join(""),new $CLJS.h(null,3,[$CLJS.EF,d,$CLJS.BL,e,$CLJS.nN,f],null),l);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();
$CLJS.bta=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.IA($CLJS.Fy);$CLJS.n($CLJS.HA("metabase.lib.drill-thru",t))&&$CLJS.GA("metabase.lib.drill-thru",t,$CLJS.rW("Applying drill thru: %s",$CLJS.H([$CLJS.sW(new $CLJS.h(null,4,[$CLJS.EF,f,$CLJS.BL,
k,usa,l,$CLJS.Vs,m],null))])),null);return $CLJS.Se.N($CLJS.a6,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.A(f);f=$CLJS.B(f);var l=$CLJS.A(f);f=$CLJS.B(f);var m=$CLJS.A(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();