var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var NI,PI,RI,cJ,gJ,oJ;$CLJS.MI=new $CLJS.M(null,"get-month","get-month",-369374731);NI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.OI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);PI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.QI=new $CLJS.M(null,"iso","iso",-1366207543);RI=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.SI=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.TI=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.UI=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.VI=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.WI=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.XI=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.YI=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.ZI=new $CLJS.M(null,"us","us",746429226);$CLJS.$I=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.aJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.bJ=new $CLJS.M(null,"now","now",-1650525531);cJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.dJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.eJ=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.fJ=new $CLJS.M(null,"get-week","get-week",-888059349);
gJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.hJ=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.iJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.jJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.kJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.lJ=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.mJ=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.nJ=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);oJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.pJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.qJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.rJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.sJ=new $CLJS.M(null,"target","target",253001721);$CLJS.wF($CLJS.YI,$CLJS.H([$CLJS.it,$CLJS.bD,$CLJS.Bs,$CLJS.bH]));$CLJS.LE.m(null,$CLJS.VI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.ME(a);return $CLJS.vd(a)?(a=$CLJS.fu.g(a,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.RC,null,$CLJS.qD,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.pF($CLJS.VI,$CLJS.bF);
for(var tJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,$CLJS.nJ],null)),uJ=null,vJ=0,wJ=0;;)if(wJ<vJ){var xJ=uJ.X(null,wJ);$CLJS.wF(xJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null),$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)]));$CLJS.pF(xJ,$CLJS.VI);wJ+=1}else{var yJ=$CLJS.y(tJ);if(yJ){var zJ=yJ;if($CLJS.Ad(zJ)){var AJ=$CLJS.lc(zJ),Tga=$CLJS.mc(zJ),Uga=AJ,Vga=$CLJS.D(AJ);tJ=Tga;uJ=Uga;vJ=Vga}else{var BJ=$CLJS.A(zJ);$CLJS.wF(BJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UE],null),$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)]));$CLJS.pF(BJ,$CLJS.VI);tJ=$CLJS.B(zJ);uJ=null;vJ=0}wJ=0}else break}
for(var CJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.TI,$CLJS.MI,$CLJS.eJ,$CLJS.mJ,$CLJS.iJ,$CLJS.SI,$CLJS.pJ],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var Wga=DJ.X(null,FJ);$CLJS.wF(Wga,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));FJ+=1}else{var GJ=$CLJS.y(CJ);if(GJ){var HJ=GJ;if($CLJS.Ad(HJ)){var IJ=$CLJS.lc(HJ),Xga=$CLJS.mc(HJ),Yga=IJ,Zga=$CLJS.D(IJ);CJ=Xga;DJ=Yga;EJ=Zga}else{var $ga=$CLJS.A(HJ);$CLJS.wF($ga,$CLJS.H([$CLJS.it,
$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));CJ=$CLJS.B(HJ);DJ=null;EJ=0}FJ=0}else break}$CLJS.wF($CLJS.XI,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZG],null)]));
for(var JJ=$CLJS.y(new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.pJ,null,$CLJS.iJ,null,$CLJS.mJ,null,$CLJS.jJ,null,$CLJS.SI,null,$CLJS.MI,null,$CLJS.TI,null,$CLJS.eJ,null],null),null)),KJ=null,LJ=0,MJ=0;;)if(MJ<LJ){var aha=KJ.X(null,MJ);$CLJS.wF(aha,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));MJ+=1}else{var NJ=$CLJS.y(JJ);if(NJ){var OJ=NJ;if($CLJS.Ad(OJ)){var PJ=$CLJS.lc(OJ),bha=$CLJS.mc(OJ),cha=PJ,dha=$CLJS.D(PJ);
JJ=bha;KJ=cha;LJ=dha}else{var eha=$CLJS.A(OJ);$CLJS.wF(eha,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)]));JJ=$CLJS.B(OJ);KJ=null;LJ=0}MJ=0}else break}$CLJS.X(oJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.QI,$CLJS.ZI,$CLJS.dJ],null));
$CLJS.yF($CLJS.fJ,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.kJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.rE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,2,[$CLJS.pt,"valid timezone ID",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Cj);return["invalid timezone ID: ",$CLJS.Mh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.TG($CLJS.Jd,$CLJS.LI.tz.names())),$CLJS.CI],null)],null));
$CLJS.yF($CLJS.OI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null)],null)],null)],
null)]));$CLJS.pF($CLJS.OI,$CLJS.VI);$CLJS.wF($CLJS.bJ,$CLJS.H([$CLJS.it,$CLJS.UC]));$CLJS.X(RI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Re(function(b){return $CLJS.qC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RC,$CLJS.qD],null))}],null)],null));
$CLJS.X(NI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RI],null)],null)],null)],null));
$CLJS.HG.g($CLJS.UI,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.pt,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.UI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.II],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.AI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.pt,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.Sv],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WG],null)],null)],null)],null)],null)],null));
$CLJS.LE.m(null,$CLJS.UI,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Sv)?$CLJS.E.g(a,$CLJS.ci)?$CLJS.qD:$CLJS.n($CLJS.oF($CLJS.aH,a))?$CLJS.RC:$CLJS.qD:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.nh($CLJS.xI,b))?$CLJS.RC:$CLJS.n($CLJS.nh($CLJS.yI,b))?$CLJS.RC:null:null;if($CLJS.n(a))return a;b=$CLJS.ME(b);b=$CLJS.vd(b)?$CLJS.eg.j($CLJS.bh,$CLJS.kf(function(c){return $CLJS.qC(c,$CLJS.Uj)}),b):b;return $CLJS.vd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(gJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.Sv],null),$CLJS.Bs],null));
$CLJS.yF($CLJS.$I,$CLJS.H([$CLJS.it,$CLJS.qD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)],null)],null)],null)]));
$CLJS.wF($CLJS.Ey,$CLJS.H([$CLJS.it,$CLJS.QD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$G],null)]));$CLJS.X(PI,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.ms,$CLJS.YG,$CLJS.zi,$CLJS.kk,$CLJS.WI,$CLJS.qJ,$CLJS.rJ,$CLJS.Ej,$CLJS.Fi,$CLJS.vi,$CLJS.hj,$CLJS.VG],null));$CLJS.X(cJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.QI,$CLJS.ZI,$CLJS.dJ],null));
$CLJS.yF($CLJS.lJ,$CLJS.H([$CLJS.it,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cJ],null)],null)],
null)],null)]));