var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var TW,UW,WW,XW,nla,ZW,$W,SW,aX,ola,pla,qla,rla,eX,fX,hX,iX,jX,kX,lX,mX,tla,ula,oX,vla,pX,wla,rX,xla,yla,zla,Ala,Bla,PW;$CLJS.QW=function(a,b){if("string"===typeof b)return PW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.RW=function(a,b,c){var d=$CLJS.dm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
TW=function(a){var b=SW;return $CLJS.EA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};UW=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Gi,a],null));};
$CLJS.VW=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Pk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};WW=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
XW=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
nla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Uk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Uk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
ZW=function(a,b){for(;;)switch(b=$CLJS.gl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),YW.h?YW.h(a):YW.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(XW,a),b))){var c=a;b=nla(a,b);a=c}else{c=$CLJS.hf.g(YW,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};$W=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.yA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
SW=function(a){return $CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.CA(WW(a).toLowerCase(),/_/,"-")):a};aX=function(a,b){var c=$W(b);return $CLJS.n(c)?(b=SW($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ola=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.lj,SW,$CLJS.ON,SW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=bX.g?bX.g(b,cX):bX.call(null,b,cX);return d.call(c,b,a)})};
pla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=SW(c);var d=ola(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.lj.h(a),$CLJS.BF)&&$CLJS.Va($CLJS.ON.h(a))?$CLJS.R.j(a,$CLJS.ON,$CLJS.AG):a};qla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=WW(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(pla(b),$CLJS.T,c)],null)}),a)};
rla=function(a){a=bX.h?bX.h(a):bX.call(null,a);return dX.h?dX.h(a):dX.call(null,a)};eX=function(a){return $CLJS.RW($CLJS.RW($CLJS.eb(function(b,c){return $CLJS.RW(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dD,$CLJS.Lea,$CLJS.BO,sla,$CLJS.Zz,$CLJS.ri],null)),$CLJS.dC,rla),$CLJS.QN,$CLJS.sV)};fX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null):a};hX=function(a){return gX.h(fX(a))};
iX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return YW($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(gX,b)))};jX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,hX(b)],null),$CLJS.hf.g(gX,c))};kX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,hX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
lX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,hX(a)],null)};mX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,gX.h(a)],null)};tla=function(a){return $CLJS.Df($CLJS.am(dX,nX(a)))};ula=function(a){for(;;)if($CLJS.n($W(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Pk.g(fX,a))};
oX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.BF);return $CLJS.n(b)?$CLJS.Qk.j(a,$CLJS.BF,gX):a};
vla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,oX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,oX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};pX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EQ);return $CLJS.n(b)?$CLJS.Qk.j(a,$CLJS.EQ,vla):a};wla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.gQ);b=$CLJS.Va(a)?qX.h?qX.h(b):qX.call(null,b):b;return $CLJS.n(a)?pX(b):b};rX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
xla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.EF);var c=$CLJS.J.g(b,$CLJS.sN),d=$CLJS.J.g(b,$CLJS.PO),e=$CLJS.J.g(b,$CLJS.gQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.PO);var l=$CLJS.UL($CLJS.Fk.g(f,$CLJS.PO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.PO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Qk.j(l,$CLJS.EF,qX):l,t=$CLJS.n(c)?$CLJS.Qk.j(m,$CLJS.sN,$CLJS.Ve($CLJS.Pk,dX)):m,u=$CLJS.n(e)?$CLJS.Qk.j(t,$CLJS.gQ,pX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.gQ);return $CLJS.n(x)?$CLJS.R.j(dX($CLJS.Fk.g(v,
$CLJS.gQ)),$CLJS.gQ,x):dX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.hE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.qV(m)])),new $CLJS.h(null,1,[$CLJS.EF,a],null),m);throw z;}};
yla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.EF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.WF);c=$CLJS.J.g(c,$CLJS.RE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.pC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.hG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,m,$CLJS.Fk.g(t,$CLJS.nG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.xV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.sX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EF,$CLJS.RE],null),$CLJS.Nk.g($CLJS.Df,$CLJS.Ve($CLJS.Ok,b)))):a};
zla=function(a){try{return yla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.JE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.EF,a],null),b);}throw c;}};
Ala=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=tX.g?tX.g(C,K):tX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return tX.g?tX.g(z,C):tX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};Bla=function(a,b){a=$CLJS.Pk.g(function(c){var d=$CLJS.be.g(b,uX);return tX.g?tX.g(c,d):tX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
PW=function PW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),PW.g?PW.g(a,k):PW.call(null,a,k)):null},null,null))};
$CLJS.sX=function sX(a){switch(arguments.length){case 3:return sX.j(arguments[0],arguments[1],arguments[2]);case 4:return sX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return sX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return sX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.sX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.sX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.sX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.sX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.sX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.sX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.sX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.sX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.sX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.sX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.sX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.sX.A=6;
var YW=function YW(a){for(;;){if($CLJS.xd(a))return $CLJS.zt(a,YW);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Kl(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return ZW($CLJS.ps,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.rs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return ZW($CLJS.ws,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return ZW($CLJS.ws,t);case "or":return ZW($CLJS.ps,t);default:return $CLJS.Pk.g(YW,a)}}else return a}},Cla=new $CLJS.M(null,"value_field","value_field",-980977878),vX=new $CLJS.M(null,"ascending","ascending",-988350486),
wX=new $CLJS.M(null,"named","named",-422393479),xX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),yX=new $CLJS.M(null,"descending","descending",-24766135),zX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Dla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),AX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),BX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Ela=new $CLJS.M(null,"rows","rows",850049680),Fla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),uX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),cX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Gla=new $CLJS.M(null,"label_field","label_field",-1573182765),Hla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ila=new $CLJS.M(null,"joined-field","joined-field",-2048778268),sla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var CX,Jla=$CLJS.Xe($CLJS.N),Kla=$CLJS.Xe($CLJS.N),Lla=$CLJS.Xe($CLJS.N),Mla=$CLJS.Xe($CLJS.N),Nla=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));CX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Nk.g(SW,$CLJS.A),Nla,Jla,Kla,Lla,Mla);CX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null);return null!=a?$CLJS.be.g(b,a):b});
CX.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,a instanceof $CLJS.M?WW(a):a],null)});CX.m(null,$CLJS.zV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(CX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zV,bX.g?bX.g(b,cX):bX.call(null,b,cX),SW(c)],null)});
CX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=bX.g?bX.g(a,cX):bX.call(null,a,cX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,function(){var d=$CLJS.n($CLJS.pB.h(c))?$CLJS.Qk.j(c,$CLJS.pB,$CLJS.zh):c;d=$CLJS.n($CLJS.nG.h(c))?$CLJS.Qk.j(d,$CLJS.nG,$CLJS.zh):d;return $CLJS.n($CLJS.sR.h(c))?$CLJS.Qk.j(d,$CLJS.sR,function(e){return $CLJS.n($CLJS.CE.h(e))?$CLJS.Qk.j(e,$CLJS.CE,$CLJS.zh):e}):d}()],null)});
CX.m(null,AX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[AX,b instanceof $CLJS.M?WW(b):b,$CLJS.zh.h(a)],null)});CX.m(null,zX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[zX,bX.g?bX.g(b,cX):bX.call(null,b,cX),$CLJS.gt,SW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[zX,bX.g?bX.g(b,cX):bX.call(null,b,cX),SW(c)],null)});
CX.m(null,$CLJS.tG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(CX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,b,c,d],null)),bX.g?bX.g(a,cX):bX.call(null,a,cX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tG,bX.g?bX.g(b,cX):bX.call(null,b,cX),$CLJS.Fd(c)?c:SW(c),SW(d)],null)});
CX.m(null,$CLJS.$I,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,b,SW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,$CLJS.Sv],null)});CX.m(null,$CLJS.YI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YI,b,SW(a)],null)});
CX.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hJ,bX.g?bX.g(b,cX):bX.call(null,b,cX),c,SW(a)],null)});CX.m(null,$CLJS.nJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nJ,bX.g?bX.g(b,cX):bX.call(null,b,cX),c,SW(a)],null)});
CX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,bX.g?bX.g(b,cX):bX.call(null,b,cX),SW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fJ,bX.g?bX.g(b,cX):bX.call(null,b,cX)],null)});
CX.m(null,$CLJS.lJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lJ,bX.g?bX.g(b,cX):bX.call(null,b,cX),SW(c),SW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,bX.g?bX.g(b,cX):bX.call(null,b,cX),SW(c)],null)});
CX.m(null,$CLJS.XI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.XI,bX.g?bX.g(b,cX):bX.call(null,b,cX),bX.g?bX.g(c,cX):bX.call(null,c,cX),SW(a)],null)});CX.m(null,$CLJS.Cj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,b,a],null)});
CX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[SW(a)],null),$CLJS.hf.h(function(c){return bX.g?bX.g(c,cX):bX.call(null,c,cX)}),b)});
var DX=function DX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))){var c=SW(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.Xn,null,$CLJS.Vr,null,$CLJS.wG,null,$CLJS.qG,null,$CLJS.it,null,$CLJS.iG,null,$CLJS.BG,null,$CLJS.CG,null,$CLJS.NF,null,$CLJS.TF,null,$CLJS.Ur,null,$CLJS.OF,null,$CLJS.vG,null,$CLJS.oG,null,$CLJS.hk,null,$CLJS.Qx,null,$CLJS.bG,null,$CLJS.uG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n($W(a))?(a=$CLJS.A(a),DX.h?DX.h(a):DX.call(null,
a)):null},Ola=new $CLJS.h(null,8,[$CLJS.lj,SW,$CLJS.gQ,function(a){a=TW(a);return $CLJS.y($CLJS.EQ.h(a))?$CLJS.Qk.j(a,$CLJS.EQ,qla):a},$CLJS.EF,new $CLJS.h(null,6,[$CLJS.XF,function EX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a)))return SW(a);if($CLJS.n(aX(wX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[wX,EX.h?EX.h(a):EX.call(null,a)],null),c)}return $CLJS.n($CLJS.n($W(a))?DX($CLJS.hd(a)):null)?$CLJS.Pk.g(EX,a):bX.g?bX.g(a,
cX):bX.call(null,a,cX)},$CLJS.XP,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[WW(u),bX.g?bX.g(t,cX):bX.call(null,t,cX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[WW(l),bX.g?bX.g(f,cX):bX.call(null,f,cX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.MR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n($W(t))?CX.h(t):$CLJS.ce(CX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n($W(l))?CX.h(l):$CLJS.ce(CX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.GQ,function(a){a=TW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.gQ);if($CLJS.n(b))return a=$CLJS.wV(a,new $CLJS.h(null,1,[$CLJS.gQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gQ],null),a=bX.g?bX.g(a,b):bX.call(null,a,b),$CLJS.wV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.gQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return bX.g?bX.g(a,b):bX.call(null,a,b)},$CLJS.PO,new $CLJS.h(null,1,[uX,eX],null),$CLJS.BN,new $CLJS.h(null,1,[uX,function(a){a=bX.g?bX.g(a,$CLJS.EF):bX.call(null,a,$CLJS.EF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.CE),d=$CLJS.J.g(b,$CLJS.RE);a=$CLJS.J.g(b,$CLJS.VE);b=$CLJS.n(c)?$CLJS.Qk.j(b,$CLJS.CE,SW):b;d=$CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Qk.j(b,$CLJS.RE,SW):b;return $CLJS.n(a)?$CLJS.Qk.j(d,$CLJS.VE,WW):d}],null)],null),$CLJS.Fy,
new $CLJS.h(null,1,[$CLJS.mO,$CLJS.Td],null),$CLJS.sN,new $CLJS.h(null,1,[uX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.lj),d=$CLJS.J.g(b,$CLJS.sJ),e=$CLJS.J.g(b,$CLJS.Yi);a=$CLJS.J.g(b,xX);b=$CLJS.n(e)?$CLJS.Qk.j(b,$CLJS.Yi,WW):b;c=$CLJS.n(c)?$CLJS.Qk.j(b,$CLJS.lj,SW):b;d=$CLJS.n(d)?$CLJS.Qk.j(c,$CLJS.sJ,function(f){return bX.g?bX.g(f,cX):bX.call(null,f,cX)}):c;d=$CLJS.n(a)?$CLJS.sX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[xX,Gla],null),function(f){return bX.g?bX.g(f,cX):bX.call(null,f,cX)}):d;return $CLJS.n(a)?
$CLJS.sX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[xX,Cla],null),function(f){return bX.g?bX.g(f,cX):bX.call(null,f,cX)}):d}],null),$CLJS.nN,function(a){return null==a?null:SW(a)},$CLJS.PO,new $CLJS.h(null,1,[uX,eX],null),BX,SW],null),bX=function bX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return bX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
bX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.zA(Ola,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=SW(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,bX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=SW(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,bX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n($W(a))?CX.h(a):$CLJS.wd(a)?$CLJS.Pk.g(function(e){return bX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),uX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.hE("Error normalizing form: {0}",$CLJS.H([$CLJS.qV(d)])),new $CLJS.h(null,3,[$CLJS.Xz,a,$CLJS.ml,c,Fla,b],null),d);}throw e;}};bX.A=1;bX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var gX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n($W(f))?$CLJS.A(f):null},e,a,b,c,d)}();gX.m(null,$CLJS.ci,function(a){return a});
gX.m(null,$CLJS.hG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(aX($CLJS.hG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return gX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,c,$CLJS.Fe($CLJS.el.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.Fe(a)],null)});
gX.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,b,a],null)});gX.m(null,$CLJS.VK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n($W(a))?gX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,null],null)});
gX.m(null,AX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,new $CLJS.h(null,1,[$CLJS.pB,a],null)],null)});gX.m(null,Hla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=hX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=hX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,a,$CLJS.R.j(c,$CLJS.CN,b)],null)});
gX.m(null,Ila,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=hX(a);return $CLJS.Se.v($CLJS.EV,a,$CLJS.R,$CLJS.H([$CLJS.rP,b]))});
gX.m(null,zX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=hX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pB);$CLJS.Va(d)||$CLJS.JS.g(d,b)?a=$CLJS.Se.v($CLJS.EV,a,$CLJS.R,$CLJS.H([$CLJS.nG,b])):(c=$CLJS.IA($CLJS.Dy),$CLJS.n($CLJS.HA("metabase.mbql.util",c))&&(b=$CLJS.hE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.GA("metabase.mbql.util",c,$CLJS.zw(),b):$CLJS.GA("metabase.mbql.util",c,$CLJS.zw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),gX.h(new $CLJS.P(null,3,5,$CLJS.Q,[zX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
gX.m(null,$CLJS.zV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=hX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,b,$CLJS.R.j(e,$CLJS.sR,$CLJS.el.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.CE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var FX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,$CLJS.ps,$CLJS.rs],null)),GX=null,HX=0,IX=0;;)if(IX<HX){var JX=GX.X(null,IX);gX.m(null,JX,function(){return function(a){return iX(a)}}(FX,GX,HX,IX,JX));IX+=1}else{var KX=$CLJS.y(FX);if(KX){var LX=KX;if($CLJS.Ad(LX)){var MX=$CLJS.lc(LX),Pla=$CLJS.mc(LX),Qla=MX,Rla=$CLJS.D(MX);FX=Pla;GX=Qla;HX=Rla}else{var NX=$CLJS.A(LX);gX.m(null,NX,function(){return function(a){return iX(a)}}(FX,GX,HX,IX,NX,LX,KX));FX=$CLJS.B(LX);GX=null;HX=0}IX=0}else break}
gX.m(null,$CLJS.QF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,hX(a),hX(b)],null),c)});gX.m(null,$CLJS.tG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=hX(a);a=$CLJS.n(XW($CLJS.hG,a))?$CLJS.EV.l(c,$CLJS.Fk,$CLJS.H([$CLJS.nG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,a],null),b)});
for(var OX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.ZF,$CLJS.sG,$CLJS.gG,$CLJS.$F,$CLJS.Sr,$CLJS.yG,$CLJS.Pr,$CLJS.Rr,$CLJS.Lr,$CLJS.Nr,$CLJS.LF,$CLJS.SF,$CLJS.JF,$CLJS.UF,$CLJS.KF],null)),PX=null,QX=0,RX=0;;)if(RX<QX){var SX=PX.X(null,RX);gX.m(null,SX,function(){return function(a){return jX(a)}}(OX,PX,QX,RX,SX));RX+=1}else{var TX=$CLJS.y(OX);if(TX){var UX=TX;if($CLJS.Ad(UX)){var VX=$CLJS.lc(UX),Sla=$CLJS.mc(UX),Tla=VX,Ula=$CLJS.D(VX);OX=Sla;PX=Tla;QX=Ula}else{var WX=$CLJS.A(UX);gX.m(null,WX,
function(){return function(a){return jX(a)}}(OX,PX,QX,RX,WX,UX,TX));OX=$CLJS.B(UX);PX=null;QX=0}RX=0}else break}gX.m(null,Ela,function(){return null});gX.m(null,$CLJS.sM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sM,gX.h(b),a],null)});
gX.m(null,wX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=gX.h;var e=$CLJS.Q;b=gX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Dla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.mE,a],null);return c.call(gX,new $CLJS.P(null,3,5,e,[$CLJS.sM,b,a],null))});
for(var XX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qx,$CLJS.iG],null)),YX=null,ZX=0,$X=0;;)if($X<ZX){var aY=YX.X(null,$X);gX.m(null,aY,function(){return function(a){return kX(a)}}(XX,YX,ZX,$X,aY));$X+=1}else{var bY=$CLJS.y(XX);if(bY){var cY=bY;if($CLJS.Ad(cY)){var dY=$CLJS.lc(cY),Vla=$CLJS.mc(cY),Wla=dY,Xla=$CLJS.D(dY);XX=Vla;YX=Wla;ZX=Xla}else{var eY=$CLJS.A(cY);gX.m(null,eY,function(){return function(a){return kX(a)}}(XX,YX,ZX,$X,eY,cY,bY));XX=$CLJS.B(cY);YX=null;ZX=0}$X=0}else break}
for(var fY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.bG,$CLJS.DG,$CLJS.NF,$CLJS.wG,$CLJS.uG,$CLJS.Xn,$CLJS.hk,$CLJS.vG,$CLJS.OF],null)),gY=null,hY=0,iY=0;;)if(iY<hY){var jY=gY.X(null,iY);gX.m(null,jY,function(){return function(a){return lX(a)}}(fY,gY,hY,iY,jY));iY+=1}else{var kY=$CLJS.y(fY);if(kY){var lY=kY;if($CLJS.Ad(lY)){var mY=$CLJS.lc(lY),Yla=$CLJS.mc(lY),Zla=mY,$la=$CLJS.D(mY);fY=Yla;gY=Zla;hY=$la}else{var nY=$CLJS.A(lY);gX.m(null,nY,function(){return function(a){return lX(a)}}(fY,gY,hY,iY,
nY,lY,kY));fY=$CLJS.B(lY);gY=null;hY=0}iY=0}else break}gX.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,hX(b),a],null)});
for(var oY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oG,$CLJS.qG],null)),pY=null,qY=0,rY=0;;)if(rY<qY){var sY=pY.X(null,rY);gX.m(null,sY,function(){return function(a){return mX(a)}}(oY,pY,qY,rY,sY));rY+=1}else{var tY=$CLJS.y(oY);if(tY){var uY=tY;if($CLJS.Ad(uY)){var vY=$CLJS.lc(uY),ama=$CLJS.mc(uY),bma=vY,cma=$CLJS.D(vY);oY=ama;pY=bma;qY=cma}else{var wY=$CLJS.A(uY);gX.m(null,wY,function(){return function(a){return mX(a)}}(oY,pY,qY,rY,wY,uY,tY));oY=$CLJS.B(uY);pY=null;qY=0}rY=0}else break}
gX.m(null,$CLJS.BG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,gX.h(b),gX.h(a)],null)});
gX.m(null,$CLJS.RF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(gX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,b],null)),bX.l(a,$CLJS.H([cX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[gX.h(v),gX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[gX.h(m),gX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
gX.m(null,$CLJS.AF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,gX.h(a),$CLJS.E.g(0,b)?1:gX.h(b)],null),$CLJS.hf.g(gX,c))});
var dX=function dX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,dX.h?dX.h(f):dX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.zt(a,dX);if($CLJS.n($W(a))){var c=function(){try{return gX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.IA($CLJS.By);if($CLJS.n($CLJS.HA("metabase.mbql.normalize",e))){var f=$CLJS.JE("Invalid clause:");f instanceof Error?$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([a])),f):$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.hE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.qV(d)])),new $CLJS.h(null,1,[$CLJS.YR,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(dX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Pk.g(dX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(dX),a):a},nX=function nX(a){return function f(d,e){try{if($CLJS.n(function(){var x=UW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=UW($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Uk.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.fE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.Vr,null,$CLJS.it,null,$CLJS.CG,null,$CLJS.Ur,null,
wX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(DX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.gE(nX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.Uk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},xY=function xY(a){return function f(d,e){try{var k=UW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.eG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.YF))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,vX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,yX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,vX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,yX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.eG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eG,hX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.YF))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,hX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.Uk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Uk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.gl.h($CLJS.hf.g(xY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.VW(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},qX=$CLJS.Nk.g(dX,function(a){var b=rX($CLJS.XF.h(a))?$CLJS.Qk.j(a,$CLJS.XF,tla):a;b=rX($CLJS.WF.h(a))?$CLJS.Qk.j(b,$CLJS.WF,ula):b;b=rX($CLJS.RE.h(a))?$CLJS.Qk.j(b,$CLJS.RE,$CLJS.Ve($CLJS.Pk,fX)):b;b=rX($CLJS.MR.h(a))?$CLJS.Qk.j(b,$CLJS.MR,xY):b;return rX($CLJS.GQ.h(a))?$CLJS.Qk.j(b,$CLJS.GQ,wla):b}),dma=new $CLJS.h(null,3,[$CLJS.gQ,$CLJS.Td,$CLJS.EF,new $CLJS.h(null,2,[$CLJS.GQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.gQ);if($CLJS.n(b))return a=
$CLJS.wV(a,new $CLJS.h(null,1,[$CLJS.gQ,$CLJS.EF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gQ],null),a=tX.g?tX.g(a,b):tX.call(null,a,b),$CLJS.wV(a,new $CLJS.h(null,1,[$CLJS.EF,$CLJS.gQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return tX.g?tX.g(a,b):tX.call(null,a,b)},$CLJS.BN,new $CLJS.h(null,1,[uX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.EF],null);return tX.g?tX.g(a,b):tX.call(null,a,b)}],null)],null),BX,$CLJS.Td],null),tX=function tX(a){switch(arguments.length){case 1:return tX.h(arguments[0]);
case 2:return tX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};tX.h=function(a){return tX.g(a,$CLJS.Cf)};tX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.zA(dma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Ala(a,b):$CLJS.wd(a)?Bla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Xz,a,$CLJS.ml,b],null),d);throw d;}};tX.A=2;
$CLJS.yY=function(){var a=$CLJS.Nk.l(tX,zla,xla,$CLJS.H([bX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.hE("Error normalizing query: {0}",$CLJS.H([$CLJS.qV(c)])),new $CLJS.h(null,1,[$CLJS.EF,b],null),c);}throw d;}}}();$CLJS.zY=function zY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.yY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.xt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=zY.g?zY.g(f,b):zY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};