var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var Y0,Ona,Z0,$0;$CLJS.W0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};Y0=function(a){return $CLJS.n(X0)?["(",$CLJS.p.h(a),")"].join(""):a};Ona=function(a,b){var c=$CLJS.Qk.v(a,$CLJS.XP,$CLJS.SD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.RE.h(a))?$CLJS.Qk.v(c,$CLJS.RE,$CLJS.be,$CLJS.mW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,$CLJS.N,$CLJS.vW(b)],null))):c};
Z0=function(a,b,c){var d=$CLJS.vW(c);return $CLJS.R.l($CLJS.X_.j(a,b,c),$CLJS.OK,$CLJS.BZ,$CLJS.H([$CLJS.T,d,$CLJS.mE,d]))};$0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.a1=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var b1=function(){function a(d,e,f){var k=$CLJS.CW(d,e);k=$CLJS.tH($CLJS.Nk.g($CLJS.ch([f]),$CLJS.vW),$CLJS.XP.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.hE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.ZR,f,$CLJS.EF,d,$CLJS.BL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.B0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=b1.j(a,b,c);return $CLJS.C0.j(a,b,c)});$CLJS.D0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Xt,$CLJS.MK,$CLJS.a1,$CLJS.yE.h(d),$CLJS.T,e,$CLJS.qE,e,$CLJS.mE,$CLJS.Y_.j(a,b,c),$CLJS.pB,$CLJS.C0.j(a,b,c),$CLJS.OK,$CLJS.BZ],null)});$CLJS.x0.m(null,$CLJS.hB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.x0.m(null,$CLJS.fB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.x0.m(null,$CLJS.gB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.x0.m(null,$CLJS.wB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.x0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.y0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var X0=!1,Pna=new $CLJS.h(null,4,[$CLJS.Ur,"+",$CLJS.it,"-",$CLJS.Vr,"×",$CLJS.CG,"÷"],null),c1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ur,$CLJS.it,$CLJS.CG,$CLJS.Vr],null)),d1=null,e1=0,f1=0;;)if(f1<e1){var Qna=d1.X(null,f1);$CLJS.pF(Qna,$0);f1+=1}else{var g1=$CLJS.y(c1);if(g1){var h1=g1;if($CLJS.Ad(h1)){var i1=$CLJS.lc(h1),Rna=$CLJS.mc(h1),Sna=i1,Tna=$CLJS.D(i1);c1=Rna;d1=Sna;e1=Tna}else{var Una=$CLJS.A(h1);$CLJS.pF(Una,$0);c1=$CLJS.B(h1);d1=null;e1=0}f1=0}else break}
$CLJS.x0.m(null,$0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Pna,c);a:{var e=X0;X0=!0;try{var f=$CLJS.At([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.$t($CLJS.Y_,a,b),d));break a}finally{X0=e}f=void 0}return Y0(f)});$CLJS.y0.m(null,$0,function(){return"expression"});
$CLJS.B0.m(null,$CLJS.AH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.rF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.C0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.C0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.x0.m(null,$CLJS.hJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.Y_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.Q0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.xA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=Y0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.y0.m(null,$CLJS.hJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.z0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.Q0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.xA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.x0.m(null,$CLJS.kG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.Y_.v(a,b,e,d)});$CLJS.y0.m(null,$CLJS.kG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.z0.j(a,b,d)});
$CLJS.Vna=function(){function a(d,e,f,k){return $CLJS.NW.l(d,$CLJS.n(e)?e:-1,Ona,$CLJS.H([$CLJS.wW($CLJS.oW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Wna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.XP.h($CLJS.CW(d,e)));return null==f?null:$CLJS.Pk.g($CLJS.$t(Z0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.j1=function(){function a(d,e){return $CLJS.Fe($CLJS.XP.h($CLJS.CW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.lW.m(null,$CLJS.mB,function(a){return a});
$CLJS.Xna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.bm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vW(t),m],null)},$CLJS.j1.g(d,e))),l=$CLJS.CW(d,e);d=$CLJS.I0.j(d,e,l);return $CLJS.Fe($CLJS.W0(function(m){return $CLJS.Va(f)||$CLJS.Lk.g($CLJS.OK.h(m),$CLJS.BZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Yna=function(){function a(d,e,f){return $CLJS.nW(Z0(d,e,b1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();