var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var aV,bV,cV,eV,fV,gV,hV,iV,jV,kV;aV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);bV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);cV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.dV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);eV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);fV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);gV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
hV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);iV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);jV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);kV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(aV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ms,new $CLJS.h(null,1,[$CLJS.pt,"Valid template tag :widget-type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.WU)));$CLJS.X(eV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ms,$CLJS.EM,$CLJS.tN,$CLJS.BF,$CLJS.Rt,$CLJS.uB,$CLJS.zP],null));
$CLJS.X(hV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.rE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mE,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Yi,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.rE,$CLJS.Es],null)],null)],null));
$CLJS.X(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Bl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null)],null));
$CLJS.X(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.BF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ON,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ks,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,$CLJS.gk],null)],null)],null)],null));
$CLJS.X(gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.EM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qM,$CLJS.rE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.XK],null)],null)],null));
$CLJS.X(kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.tN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aR,$CLJS.WK],null)],null)],null));$CLJS.X(jV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ms],null),$CLJS.KU));
$CLJS.X(bV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null)],null)],null));
$CLJS.X(cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.lj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bV],null)],null)],null)],null));
$CLJS.X($CLJS.dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.us,$CLJS.rE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));