var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");
'use strict';var au,Eba,Fba,du,Gba,gu,hu;
$CLJS.$t=function(a,b,c){return function(){function d(t,u,v){return a.N?a.N(b,c,t,u,v):a.call(null,b,c,t,u,v)}function e(t,u){return a.v?a.v(b,c,t,u):a.call(null,b,c,t,u)}function f(t){return a.j?a.j(b,c,t):a.call(null,b,c,t)}function k(){return a.g?a.g(b,c):a.call(null,b,c)}var l=null,m=function(){function t(v,x,z,C){var G=null;if(3<arguments.length){G=0;for(var K=Array(arguments.length-3);G<K.length;)K[G]=arguments[G+3],++G;G=new $CLJS.w(K,0,null)}return u.call(this,v,x,z,G)}function u(v,x,z,C){return $CLJS.Se.l(a,
b,c,v,x,$CLJS.H([z,C]))}t.A=3;t.B=function(v){var x=$CLJS.A(v);v=$CLJS.B(v);var z=$CLJS.A(v);v=$CLJS.B(v);var C=$CLJS.A(v);v=$CLJS.Lc(v);return u(x,z,C,v)};t.l=u;return t}();l=function(t,u,v,x){switch(arguments.length){case 0:return k.call(this);case 1:return f.call(this,t);case 2:return e.call(this,t,u);case 3:return d.call(this,t,u,v);default:var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.l(t,u,v,z)}throw Error("Invalid arity: "+
arguments.length);};l.A=3;l.B=m.B;l.o=k;l.h=f;l.g=e;l.j=d;l.l=m.l;return l}()};au=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):new $CLJS.P(null,3,5,$CLJS.Q,[a,b,null],null)};Eba=function(a){return $CLJS.y(a)?$CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return $CLJS.R.j(b,d,c)},$CLJS.Df($CLJS.df($CLJS.Se.g($CLJS.Jk,$CLJS.Xg(a)),null)),a):null};
Fba=function(a,b,c){var d=$CLJS.J.g(a,c),e=$CLJS.J.g(b,c),f=$CLJS.bu(d,e),k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null);f=$CLJS.I(f,2,null);a=$CLJS.Id(a,c);b=$CLJS.Id(b,c);d=a&&b&&(null!=f||null==d&&null==e);return new $CLJS.P(null,3,5,$CLJS.Q,[!a||null==k&&d?null:$CLJS.Ee([c,k]),!b||null==l&&d?null:$CLJS.Ee([c,l]),d?$CLJS.Ee([c,f]):null],null)};
du=function(a,b){var c=$CLJS.hf.g,d=cu.j,e=$CLJS.zd(a)?a:$CLJS.Df(a),f=$CLJS.zd(b)?b:$CLJS.Df(b);a=$CLJS.D(a);b=$CLJS.D(b);return $CLJS.Df(c.call($CLJS.hf,Eba,d.call(cu,e,f,$CLJS.lh(0,a>b?a:b))))};Gba=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fe($CLJS.eu.g(a,b)),$CLJS.Fe($CLJS.eu.g(b,a)),$CLJS.Fe($CLJS.fu.g(a,b))],null)};
gu=function(a){if(null!=a&&null!=a.gh)a=a.gh(a);else{var b=gu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=gu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("EqualityPartition.equality-partition",a);}return a};hu=function(a,b){if(null!=a&&null!=a.fh)a=a.fh(a,b);else{var c=hu[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=hu._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("Diff.diff-similar",a);}return a};
$CLJS.bu=function(a,b){return $CLJS.E.g(a,b)?new $CLJS.P(null,3,5,$CLJS.Q,[null,null,a],null):$CLJS.E.g(gu(a),gu(b))?hu(a,b):au(a,b)};$CLJS.fu=function fu(a){switch(arguments.length){case 1:return fu.h(arguments[0]);case 2:return fu.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return fu.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.fu.h=function(a){return a};
$CLJS.fu.g=function(a,b){for(;;)if($CLJS.D(b)<$CLJS.D(a)){var c=a;a=b;b=c}else return $CLJS.eb(function(d,e){return function(f,k){return $CLJS.Id(e,k)?f:$CLJS.Gk.g(f,k)}}(a,b),a,a)};$CLJS.fu.l=function(a,b,c){a=$CLJS.Wt(function(d){return-$CLJS.D(d)},$CLJS.be.l(c,b,$CLJS.H([a])));return $CLJS.eb($CLJS.fu,$CLJS.A(a),$CLJS.Lc(a))};$CLJS.fu.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.fu.A=2;
$CLJS.eu=function eu(a){switch(arguments.length){case 1:return eu.h(arguments[0]);case 2:return eu.g(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return eu.l(arguments[0],arguments[1],new $CLJS.w(c.slice(2),0,null))}};$CLJS.eu.h=function(a){return a};$CLJS.eu.g=function(a,b){return $CLJS.D(a)<$CLJS.D(b)?$CLJS.eb(function(c,d){return $CLJS.Id(b,d)?$CLJS.Gk.g(c,d):c},a,a):$CLJS.eb($CLJS.Gk,a,b)};
$CLJS.eu.l=function(a,b,c){return $CLJS.eb($CLJS.eu,a,$CLJS.be.g(c,b))};$CLJS.eu.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};$CLJS.eu.A=2;var iu=new $CLJS.M(null,"atom","atom",-397043653);var cu=function cu(a){switch(arguments.length){case 2:return cu.g(arguments[0],arguments[1]);case 3:return cu.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};cu.g=function(a,b){return cu.j(a,b,$CLJS.Zt.g($CLJS.Xg(a),$CLJS.Xg(b)))};cu.j=function(a,b,c){return $CLJS.eb(function(d,e){return $CLJS.mh($CLJS.hf.j($CLJS.el,d,e))},new $CLJS.P(null,3,5,$CLJS.Q,[null,null,null],null),$CLJS.hf.g($CLJS.$t(Fba,a,b),c))};cu.A=3;
gu["null"]=function(){return iu};gu.string=function(){return iu};gu.number=function(){return iu};gu.array=function(){return $CLJS.yj};gu["function"]=function(){return iu};gu["boolean"]=function(){return iu};
gu._=function(a){return(null!=a?a.C&1024||$CLJS.Bc===a.Ug||(a.C?0:$CLJS.$a($CLJS.Cb,a)):$CLJS.$a($CLJS.Cb,a))?$CLJS.gk:(null!=a?a.C&4096||$CLJS.Bc===a.Yg||(a.C?0:$CLJS.$a($CLJS.Gb,a)):$CLJS.$a($CLJS.Gb,a))?$CLJS.Zi:(null!=a?a.C&16777216||$CLJS.Bc===a.qf||(a.C?0:$CLJS.$a($CLJS.Wb,a)):$CLJS.$a($CLJS.Wb,a))?$CLJS.yj:iu};hu["null"]=function(a,b){return au(a,b)};hu.string=function(a,b){return au(a,b)};hu.number=function(a,b){return au(a,b)};hu.array=function(a,b){return du(a,b)};
hu["function"]=function(a,b){return au(a,b)};hu["boolean"]=function(a,b){return au(a,b)};hu._=function(a,b){var c=function(){var d=gu(a);d=d instanceof $CLJS.M?d.T:null;switch(d){case "atom":return au;case "set":return Gba;case "sequential":return du;case "map":return cu;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}();return c.g?c.g(a,b):c.call(null,a,b)};