var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.QJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.RJ=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.SJ=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var TJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.ps],null)),UJ=null,VJ=0,WJ=0;;)if(WJ<VJ){var fha=UJ.X(null,WJ);$CLJS.yF(fha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Xn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)],null)],null)]));WJ+=1}else{var XJ=$CLJS.y(TJ);if(XJ){var YJ=XJ;if($CLJS.Ad(YJ)){var ZJ=$CLJS.lc(YJ),gha=$CLJS.mc(YJ),
hha=ZJ,iha=$CLJS.D(ZJ);TJ=gha;UJ=hha;VJ=iha}else{var jha=$CLJS.A(YJ);$CLJS.yF(jha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Xn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)],null)],null)]));TJ=$CLJS.B(YJ);UJ=null;VJ=0}WJ=0}else break}$CLJS.wF($CLJS.rs,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)]));
for(var $J=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.yG],null)),aK=null,bK=0,cK=0;;)if(cK<bK){var kha=aK.X(null,cK);$CLJS.yF(kha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Xn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null)],null)]));cK+=1}else{var dK=$CLJS.y($J);if(dK){var eK=dK;if($CLJS.Ad(eK)){var fK=$CLJS.lc(eK),lha=$CLJS.mc(eK),
mha=fK,nha=$CLJS.D(fK);$J=lha;aK=mha;bK=nha}else{var oha=$CLJS.A(eK);$CLJS.yF(oha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,new $CLJS.h(null,1,[$CLJS.Xn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null)],null)],null)]));$J=$CLJS.B(eK);aK=null;bK=0}cK=0}else break}
for(var gK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pr,$CLJS.Rr,$CLJS.Lr,$CLJS.Nr],null)),hK=null,iK=0,jK=0;;)if(jK<iK){var pha=hK.X(null,jK);$CLJS.wF(pha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));jK+=1}else{var kK=$CLJS.y(gK);if(kK){var lK=kK;if($CLJS.Ad(lK)){var mK=$CLJS.lc(lK),qha=$CLJS.mc(lK),rha=mK,sha=$CLJS.D(mK);gK=qha;hK=rha;iK=sha}else{var tha=$CLJS.A(lK);$CLJS.wF(tha,$CLJS.H([$CLJS.it,$CLJS.Jj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));gK=$CLJS.B(lK);hK=null;iK=0}jK=0}else break}$CLJS.wF($CLJS.KF,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));
$CLJS.wF($CLJS.QF,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)]));
for(var nK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JF,$CLJS.UF],null)),oK=null,pK=0,qK=0;;)if(qK<pK){var uha=oK.X(null,qK);$CLJS.wF(uha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)]));qK+=1}else{var rK=$CLJS.y(nK);if(rK){var sK=rK;if($CLJS.Ad(sK)){var tK=$CLJS.lc(sK),vha=$CLJS.mc(sK),wha=tK,xha=$CLJS.D(tK);nK=vha;oK=wha;pK=xha}else{var yha=$CLJS.A(sK);$CLJS.wF(yha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)]));nK=$CLJS.B(sK);
oK=null;pK=0}qK=0}else break}
for(var uK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LF,$CLJS.SF],null)),vK=null,wK=0,xK=0;;)if(xK<wK){var zha=vK.X(null,xK);$CLJS.wF(zha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)]));xK+=1}else{var yK=$CLJS.y(uK);if(yK){var zK=yK;if($CLJS.Ad(zK)){var AK=$CLJS.lc(zK),Aha=$CLJS.mc(zK),Bha=AK,Cha=$CLJS.D(AK);uK=Aha;vK=Bha;wK=Cha}else{var Dha=$CLJS.A(zK);$CLJS.wF(Dha,$CLJS.H([$CLJS.it,$CLJS.Jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)]));uK=$CLJS.B(zK);
vK=null;wK=0}xK=0}else break}
for(var BK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null),CK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ZF,$CLJS.sG,$CLJS.gG,$CLJS.$F],null)),DK=null,EK=0,FK=0;;)if(FK<EK){var GK=DK.X(null,FK);$CLJS.HG.v(GK,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,GK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.wE,BK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.fF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)],null));FK+=1}else{var HK=$CLJS.y(CK);if(HK){var IK=HK;if($CLJS.Ad(IK)){var JK=$CLJS.lc(IK),Eha=$CLJS.mc(IK),Fha=JK,Gha=$CLJS.D(JK);CK=Eha;DK=Fha;EK=Gha}else{var KK=$CLJS.A(IK);$CLJS.HG.v(KK,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,KK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.wE,BK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.fF],null)],null));CK=$CLJS.B(IK);DK=null;EK=0}FK=0}else break}
$CLJS.HG.v($CLJS.tG,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.tG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.wE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fs],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.Sv,$CLJS.Ux,$CLJS.PF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bH],null)],null));$CLJS.HG.v($CLJS.GF,$CLJS.it,$CLJS.Jj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.GF],null),$CLJS.wE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.jE,$CLJS.rE],null)],null));
$CLJS.X($CLJS.QJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,$CLJS.RJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ms,$CLJS.Sr,$CLJS.yG,$CLJS.QF,$CLJS.KF,$CLJS.Pr,$CLJS.Lr,$CLJS.Rr,$CLJS.Nr,$CLJS.JF,$CLJS.UF,$CLJS.LF,$CLJS.SF,$CLJS.gG,$CLJS.$F,$CLJS.ZF,$CLJS.sG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,$CLJS.Wi],null)],null));